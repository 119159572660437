@import "../variables.module";

.sportLink {
  display: flex;
  align-items: center;
  font-size: 13px;
  padding: 12px;
  padding-left: 12px;
  margin-bottom: 6px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: $radius;
  transition: all 0.1s ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  svg {
    margin-right: 6px;
  }

  span {
    margin-left: auto;
    font-size: 1rem;
  }
}

.side-bar-container {
  background-color: $ninthBackground;
  width: 250px;
  border-top-left-radius: 0;
  height: calc(100vh - 68px);
  z-index: 5000;
  overflow: hidden;
  transition: width 50ms $easeInOut3;
  position: sticky;
  top: 68px;

  .new-icon {
    margin-left: 10px;
    font-size: 10px;
    background-color: #d1403f;
    padding: 4px;
    border-radius: $radius;
    position: absolute;
    right: 10px;
  }

  .new-icon-default {
    margin-left: 10px;
    font-size: 10px;
    padding: 7px 8px;
    border-radius: 4.2px;
    border: 1px solid #87c1f2;
    text-transform: uppercase;
    margin-left: auto;

    border-radius: 4.222px;
    border: 1px solid #87c1f2;
    background: radial-gradient(
      76.33% 100% at 50% 100%,
      rgba(0, 119, 219, 0.5) 0%,
      rgba(0, 119, 219, 0.1) 100%
    );
    box-shadow: 0 0 6.111px rgba(0, 119, 219, 0.3);
  }

  @media (max-width: $breakPointOne) {
    border-radius: 0;
    width: 360px;
    left: 0;
    z-index: 1005;
    margin: 0;
    padding: 0;
    opacity: 0;
    pointer-events: none;
    transform: translateX(-100%);
    transition:
      opacity 0.1s $easeInOut3,
      transform 0.1s $easeInOut3;
    z-index: 1005;

    height: calc(100% - 67px);
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;

    &.menu-toggle {
      opacity: 1;
      transform: translateX(0%);
      pointer-events: auto;
    }
  }

  @media (max-width: 780px) {
    width: 100%;
  }

  @media (max-width: 520px) {
    top: 68px !important;
    height: calc(100% - 135px) !important;
  }

  .login-register {
    display: none;

    @media (max-width: 520px) {
      display: block;
    }
  }

  .large-version {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
    transition: opacity 50ms $easeInOut3;
  }

  .mini-version {
    padding-left: 15px;
    padding-right: 15px;
    transition: opacity 50ms $easeInOut3;
    opacity: 0;
    pointer-events: none;

    .sidebar-footer {
      .odds-back > .drop-down-container {
        display: block;
        font-size: 4rem;
        width: 155px !important;
        background-color: red !important;
      }
    }

    .drop-down-container {
      display: block;
      font-size: 4rem;
      width: 155px !important;
      background-color: red !important;
    }

    .scrolling-content {
      top: 350px;
      transition: top 0.1s ease-in-out;
      // overflow-y: initial;
    }
  }

  .mini-search {
    padding-top: 5px;
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (min-width: $breakPointOne) {
    &.mini {
      width: 80px;

      .large-version {
        transition: opacity 50ms $easeInOut3;
        opacity: 0;
        pointer-events: none;
      }

      .mini-version {
        opacity: 1;
        pointer-events: auto;
      }

      @media (max-width: $breakPointOne) {
        width: 360px;

        .large-version {
          transition: opacity 100ms $easeInOut3;
          opacity: 1;
          pointer-events: auto;
        }

        .mini-version {
          opacity: 0;
          pointer-events: none;
        }
      }

      @media (max-width: 520px) {
        width: 100% !important;
      }
    }
  }

  h2 {
    padding-bottom: 30px;
  }

  .section-nav {
    // background-color: $btnActiveFill;
    width: 100%;
    // display: flex;
    border-radius: $radius;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .gradient {
    pointer-events: none;
    left: 0;
    z-index: 50;
    height: 5rem;
    width: 100%;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
    position: absolute;
    opacity: 1;

    &__top {
      top: 0;
      @media screen and (max-width: 1101px) {
        top: 65px;
      }
      @media screen and (max-width: 391px) {
        top: 135px;
      }
      background-image: linear-gradient(to bottom, #13172e, transparent);
    }

    &__bottom {
      bottom: 60px;
      background-image: linear-gradient(to top, #13172e, transparent);
    }
  }

  .scrolling-content {
    position: absolute;
    display: block;
    padding-top: 0px;
    overflow-y: auto;
    overflow-x: visible;
    bottom: 70px;
    top: 0px;
    @include scrollbars(1px, transparent, transparent);
    left: 0px;
    right: 0px;
    padding-right: 16px;
    padding-left: 16px;
    transition: top 0.1s ease-in-out;
    margin-right: 3px;

    &.casino-games {
      @media (max-width: $breakPointOne) {
        top: 80px;
      }

      @media (max-width: $breakPointTwo) {
        top: 70px;
      }
    }

    &.space-for-login {
      @media (max-width: $breakPointFour) {
        top: 150px;
      }
    }
  }

  .game-list,
  .sports-list {
    display: flex;
    flex-direction: column;
    // gap: 10px;
    width: 100%;
    position: relative;
    // z-index: 500;
  }

  .game-list {
    margin-bottom: 5px;
    gap: 4px;
  }

  .coming-soon {
    font-size: 10px;
    padding: 8px;
    background-color: $primaryBackgroundColor;
    margin-left: 20px;
  }

  .sidebar-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 56px;
    background-color: $ninthBackground;
    z-index: 51;

    .promotions-button {
      padding: 0px 0px 0px 18px;
    }

    .promotions-button-mini {
      display: flex;
      justify-content: center;
    }

    .odds-back {
      display: none;
      padding-left: $padding;
      padding-right: $padding;
    }

    .lang-back {
      padding-left: $padding;
      padding-right: $padding;
    }
  }
}

.game-list-heading {
  padding-left: 10px;
  display: flex;
  gap: 10px;
  background-color: #212749;
  padding-top: 14px;
  padding-bottom: 14px;
  margin-right: -16px;
  margin-left: -16px;
  padding-left: 25px;
  align-items: center;

  h3 {
    font-size: 14px;
    color: #b2b7d0;
  }

  .icon {
    width: 20px;
  }
}

.sized-bar {
  // margin-top: 20px;
  height: 1px;
  margin-top: 18px;
  margin-bottom: 18px;
  background: rgba(255, 255, 255, 0.1);
}

.side-bar-divider {
  height: 1px;
  background-color: #212749;
  margin-top: 20px;
  // margin-bottom: 10px;
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}

.bottom-buttons-nav {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.icon-container-sports {
  svg {
    min-width: 32px;
    min-height: 32px;
    margin-right: 12px;
  }
}

.side-menu-nav {
  &.icon-container-sports {
    min-height: 48px;
    padding: 8px 12px;
    display: flex;
    align-items: center;

    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
    }
  }
}
