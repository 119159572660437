.accordion-container {
  $bg: var(--bg);

  .accordion-title-container {
    overflow: hidden;
    padding: 12px 16px;
    display: flex;

    border-radius: 6px;
    border: 1px solid #313757;
    background: linear-gradient(
      180deg,
      rgba(124, 131, 177, 0.2) 0%,
      rgba(94, 103, 158, 0.2) 100%
    );
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    cursor: pointer;

    &.open.merged {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    div {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    .heading {
      pointer-events: none;
      color: white;
      font-size: 13px;
      transition: color 0.2s ease-in-out;
      width: 100%;
    }

    .default-svg {
      width: 18px;
      height: 18px;

      svg {
        display: block;
        pointer-events: none;
        width: 18px;
        height: 18px;

        path {
          fill: white;
        }
      }
    }

    .custom-svg {
      width: 18px;
      height: 18px;
      svg {
        pointer-events: none;
        width: 18px;
        height: 18px;
      }
    }

    .arrow-icon-container {
      svg,
      path,
      circle {
        transition: transform 0.2s;
        fill: #7179a5;
      }

      svg {
        width: 16px;
        height: 16px;
      }

      &.rotate {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    // svg,
    path,
    circle {
      transition: fill 0.2s;
    }

    svg {
      path:nth-child(4) {
        fill: rgb(21, 26, 51);
      }
    }

    &:hover {
      background: linear-gradient(
        180deg,
        rgba(124, 131, 177, 0.3) 0%,
        rgba(94, 103, 158, 0.3) 100%
      );

      svg {
        path:nth-child(4) {
          fill: rgb(21, 26, 51);
        }
      }
    }
  }

  .accordion-child-container {
    display: flex;
    flex-direction: column;
    // background: #151a33;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0px;
    gap: 1px;

    &.merged {
      padding: 12px;
      background: $bg;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}
